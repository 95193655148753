<template>
  <div class="blockContent">
    <FavoritesHeader
      @addNewFavorites="addNewFavorites"
      :showTransferIcon="true"
    ></FavoritesHeader>
    <FavoritesListBlock
      :favoritesList="favoritesList"
      @selectFavorites="selectFavorites"
    ></FavoritesListBlock>
    <div class="blockFooter">
      <div class="footerButton closeButtonStyle" @click.stop="closeDialog">
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
      <div
        class="footerButton commomButtonHover"
        @click.stop="selectCurrentFolder"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import FavoritesHeader from "../favoritesHeader";
import FavoritesListBlock from "./favoritesListBlock";
import { movefavorites } from "@/api/newVersion/interfaceForFavorites";
export default {
  name: "transferFavoritesBlock",
  components: {
    FavoritesHeader,
    FavoritesListBlock,
  },
  props: {
    // 收藏夹列表
    favoritesList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    favoritesList: {
      handler(val, old) {
        if (val != old && val.length > 0) {
          const item = val.find((item) => item.isSelected);
          this.currentlySelected = item;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      // 当前选中的收藏夹
      currentlySelected: null,
    };
  },
  methods: {
    // 关闭dialog弹窗
    closeDialog() {
      this.currentlySelected = null;
      this.$emit("closeDialog");
    },
    // 选择当前文件夹
    async selectCurrentFolder() {
      if (this.currentlySelected) {
        let params = {
          bookmarkId: this.currentlySelected.id,
        };
        let result = await movefavorites(
          this.$store.state.favorites.needToOperatObjectInformation.collectId,
          params
        );
        if (result.code == 200) {
          this.$store.commit("setNeedRefreshFavoritesList", true);
          this.closeDialog();
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    // 添加新的收藏夹
    addNewFavorites() {
      // 这一步是展示添加新收藏夹组件
      this.$store.commit("setNeedToShowInDialog", "addFolderBlock");
      // 这一步是隐藏添加新收藏夹不显示返回按钮
      this.$store.commit("setShowAddFolderBlockBackButton", "fromDialog");
    },
    // 选择当前收藏夹
    selectFavorites(index) {
      this.$emit("selectFavorites", index);
    },
  },
};
</script>
<style lang="stylus" scoped>
.blockContent
  width 100%;
  overflow hidden;
  padding 20px;
  box-sizing border-box;
  .blockFooter
    width 100%;
    height 34px;
    margin-top 24px;
    display flex;
    align-items center;
    justify-content center;
    .footerButton
      height 34px;
      width 90px;
      cursor pointer;
      overflow hidden;
      border-radius 4px;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        color #FFFFFF;
        font-size 22px;
    .closeButtonStyle
      background #E6E6E6;
      margin-right 11px;
      transition background .3s;
      &:hover
        background #F0F0F0;
</style>
