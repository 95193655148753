import { render, staticRenderFns } from "./favoritesListBlock.vue?vue&type=template&id=f8ed7f28&scoped=true"
import script from "./favoritesListBlock.vue?vue&type=script&lang=js"
export * from "./favoritesListBlock.vue?vue&type=script&lang=js"
import style0 from "./favoritesListBlock.vue?vue&type=style&index=0&id=f8ed7f28&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ed7f28",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\new_front_melinked\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f8ed7f28')) {
      api.createRecord('f8ed7f28', component.options)
    } else {
      api.reload('f8ed7f28', component.options)
    }
    module.hot.accept("./favoritesListBlock.vue?vue&type=template&id=f8ed7f28&scoped=true", function () {
      api.rerender('f8ed7f28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/newVersion/components/favorites/dialogInner/favoritesListBlock.vue"
export default component.exports