var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "blockContent" }, [
    _c("div", { staticClass: "blockHeader" }, [
      _vm.showBackButton === "fromDialog"
        ? _c(
            "div",
            {
              staticClass: "backButton",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.goBackPreviousBlock.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-left iconStyle" })]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "closeButton",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.closeDialog.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
      )
    ]),
    _c("div", { staticClass: "blockTitle" }, [
      _c("span", { staticClass: "fontStyle_1" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("addFolder")) + "\n    ")
      ]),
      _c("span", { staticClass: "fontStyle_2" }, [
        _vm._v("\n      " + _vm._s(`(<20)`) + "\n    ")
      ])
    ]),
    _c("div", { staticClass: "inputBlock" }, [
      _c(
        "div",
        { staticClass: "inputBox" },
        [
          _vm.inputFolderNameFlag
            ? _c("el-input", {
                ref: "folderNameInputter",
                attrs: { maxlength: "20", placeholder: _vm.placeholderIcon },
                on: { blur: _vm.folderNameBlur },
                model: {
                  value: _vm.folderName,
                  callback: function($$v) {
                    _vm.folderName = $$v
                  },
                  expression: "folderName"
                }
              })
            : _c(
                "div",
                {
                  staticClass: "showFolderNameItem",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.startInputFolderName.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "folderNameText" }, [
                    _vm._v(_vm._s(_vm.folderName))
                  ]),
                  _c("img", {
                    staticClass: "inputIcon",
                    attrs: {
                      src: require("../../../../assets/images/newEditCenter/editIcon.png"),
                      alt: ""
                    }
                  })
                ]
              )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "blockFooter" }, [
      _c(
        "div",
        {
          staticClass: "footerButton closeButtonStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.closeDialog.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
      ),
      _c(
        "div",
        {
          staticClass: "footerButton commomButtonHover",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.addFolderFn.apply(null, arguments)
            }
          }
        },
        [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }