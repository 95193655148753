var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "blockContent" },
    [
      _c("FavoritesHeader", {
        attrs: { showTransferIcon: true },
        on: { addNewFavorites: _vm.addNewFavorites }
      }),
      _c("FavoritesListBlock", {
        attrs: { favoritesList: _vm.favoritesList },
        on: { selectFavorites: _vm.selectFavorites }
      }),
      _c("div", { staticClass: "blockFooter" }, [
        _c(
          "div",
          {
            staticClass: "footerButton closeButtonStyle",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.closeDialog.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
        ),
        _c(
          "div",
          {
            staticClass: "footerButton commomButtonHover",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectCurrentFolder.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }