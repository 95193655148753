<template>
  <div class="favoritesListBox">
    <div
      class="favoritesItem"
      v-for="(item, index) in favoritesList"
      :key="index"
      @click.stop="selectFavorites(index)"
      :class="item.isSelected ? 'hasSelectedStyle' : ''"
      :title="item.name"
    >
      <div class="favoritesInfo">
        <div class="favoritesName">{{ item.name }}</div>
        <div class="favoritesCount">{{ `(${item.count})` }}</div>
      </div>
      <div class="selectedIcon" v-if="item.isSelected">
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "favoritesListBlock",
  props: {
    // 收藏夹列表
    favoritesList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    // 选中这个文件夹
    selectFavorites(index) {
      this.$emit("selectFavorites", index);
    },
  },
};
</script>
<style lang="stylus" scoped>
.favoritesListBox
  width 100%;
  margin-top 10px;
  max-height 300px;
  overflow-x hidden;
  overflow-y auto;
  scrollbar-width: none;
  &::-webkit-scrollbar
    width 4px;
  &::-webkit-scrollbar-thumb
    border-radius: 2px;
    background: rgba(100,100,100,0.5);
    box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
  &::-webkit-scrollbar-track
    border-radius: 2px;
    background: rgba(0,0,0,0.05);
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  .hasSelectedStyle
    background #EBFFF1 !important;
    border-bottom none !important;
    .favoritesInfo
      .favoritesName
        color #33CC66 !important;
      .favoritesCount
        color #33CC66 !important;
  .favoritesItem
    height 57px;
    width 100%;
    cursor pointer;
    border-bottom 1px solid rgba(0,0,0,0.16);
    display flex;
    align-items center;
    justify-content flex-start;
    padding 0 17px 0 9px;
    box-sizing border-box;
    .favoritesInfo
      flex 1;
      min-width 0;
      height 100%;
      display flex;
      align-items center;
      justify-content flex-start;
      .favoritesName
        max-width 100%;
        font-size 14px;
        line-height 56px;
        color #343434;
        overflow hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      .favoritesCount
        min-width 30px;
        line-height 56px;
        flex-shrink 0
        font-size 12px;
        color #8F8F8F;
        margin-left 8px;
    .selectedIcon
      width 18px;
      height 100%;
      flex-shrink 0;
      margin-left 10px;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 18px;
        color #33CC66;
</style>
