<template>
  <div class="blockContent">
    <div class="blockHeader">
      <div class="backButton" v-if="showBackButton === 'fromDialog'" @click.stop="goBackPreviousBlock">
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
      <div class="closeButton" @click.stop="closeDialog">
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
    </div>
    <div class="blockTitle">
      <span class="fontStyle_1">
        {{$t("addFolder")}}
      </span>
      <span class="fontStyle_2">
        {{`(<20)`}}
      </span>
    </div>
    <div class="inputBlock">
      <div class="inputBox">
        <el-input maxlength="20" ref="folderNameInputter" @blur="folderNameBlur" v-if="inputFolderNameFlag" v-model="folderName" :placeholder="placeholderIcon"></el-input>
        <div v-else class="showFolderNameItem" @click.stop="startInputFolderName">
          <span class="folderNameText">{{folderName}}</span>
          <img class="inputIcon" :src="require('../../../../assets/images/newEditCenter/editIcon.png')" alt="">
        </div>
      </div>
    </div>
    <div class="blockFooter">
      <div class="footerButton closeButtonStyle" @click.stop="closeDialog">
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
      <div class="footerButton commomButtonHover" @click.stop="addFolderFn">
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { addNewFavorites } from "@/api/newVersion/interfaceForFavorites";
export default {
  name: "addFolderBlock",
  props: {
  },
  computed: {
    // 是否展示返回按钮(fromDialog：从dialog弹窗来的，就显示返回按钮；fromPopover：从气泡弹窗来的，不显示返回按钮)
    showBackButton() {
      return this.$store.state.favorites.showAddFolderBlockBackButton
    }
  },
  data() {
    return {
      // 文件名
      folderName: "",
      // 输入框展示状态
      inputFolderNameFlag: false,
      // 昵称输入框默认文本
      placeholderIcon: '\ue6a1',
    }
  },
  watch: {
    // 监听昵称输入框的状态
    inputFolderNameFlag: {
      handler(val,old) {
        if(val && val !== old) {
          this.$nextTick(()=>{
            this.$refs.folderNameInputter.focus();
          })
        }
      },
      deep: true
    },
  },
  methods: {
    // 关闭dialog弹窗
    closeDialog() {
      this.$emit("closeDialog")
    },
    // 返回上一个组件
    goBackPreviousBlock() {
      this.$emit("goBackPreviousBlock");
    },
    // 添加新的文件夹
    async addFolderFn() {
      if(this.globalTrim(this.folderName) !== '') {
        let params = {
          name: this.folderName
        }
        let result = await addNewFavorites(params);
        if(result.code == 200) {
          // 如果是来自气泡弹窗的话，就关闭当前dialog弹窗；否则返回上一级
          if(this.showBackButton === 'fromPopover') {
            this.$store.commit("setNeedRefreshFavoritesList",true);
            this.closeDialog();
          } else {
            this.goBackPreviousBlock();
          }
        } else {
          this.$message({
            type: "error",
            message: result.message
          })
        }
      }
    },
    // 失去焦点
    folderNameBlur() {
      this.inputFolderNameFlag = false;
    },
    // 开始输入
    startInputFolderName() {
      this.inputFolderNameFlag = true;
    }
  }
}
</script>
<style lang="stylus" scoped>
  .blockContent
    width 100%;
    overflow hidden;
    .blockHeader
      padding 20px 25px;
      overflow hidden;
      .backButton
        width 24px;
        height 24px;
        float left;
        cursor pointer;
        line-height 22px;
        text-align center;
        border-radius 50%;
        background #E8E8E8;
        .iconStyle
          font-size 12px;
          color #000000;
          font-weight bold;
      .closeButton
        width 18px;
        height 18px;
        line-height 22px;
        float right;
        cursor pointer;
        .iconStyle
          font-size 18px;
          color #CCCCCC;
    .blockTitle
      width 100%;
      text-align center;
      .fontStyle_1
        font-size 14px;
        color #343434;
        line-height 20px;
      .fontStyle_2
        font-size 12px;
        color #8F8F8F;
        line-height 20px;
    .inputBlock
      width 100%;
      height 40px;
      overflow hidden;
      display flex;
      align-items center;
      justify-content center;
      margin 30px 0 50px 0;
      .inputBox
        width 310px;
        height 40px;
        overflow hidden;
        border-radius 4px;
        box-sizing border-box;
        border 1px solid #8F8F8F;
        .showFolderNameItem
          height 40px;
          cursor pointer;
          user-select none;
          font-size 14px;
          color #343434;
          display flex;
          align-items center;
          justify-content flex-start;
          .folderNameText
            margin 0 5px 0 20px
          .inputIcon
            width 12px;
            height 12px;
    .blockFooter
      width 100%;
      height 34px;
      margin-bottom 24px;
      display flex;
      align-items center;
      justify-content center;
      .footerButton
        height 34px;
        width 90px;
        cursor pointer;
        overflow hidden;
        border-radius 4px;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          color #FFFFFF;
          font-size 22px;
      .closeButtonStyle
        background #E6E6E6;
        margin-right 11px;
        transition background .3s;
        &:hover
          background #F0F0F0;


</style>
<style lang="stylus">
  .blockContent
    .inputBlock
      .el-input
        .el-input__inner
          font-family 'iconfont_Me';
          caret-color #33CC66;
          height 40px;
          line-height 40px;
          border none !important;
          padding 0 0 0 20px !important;

</style>