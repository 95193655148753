var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "favoritesHeader" }, [
    _c("div", { staticClass: "favoritesIcon" }, [
      _vm.showTransferIcon
        ? _c("img", {
            attrs: {
              src: require("../../../assets/images/favorites/transferLargeIcon.png")
            }
          })
        : _c("img", {
            attrs: {
              src: require("../../../assets/images/newPersonalCenter/favoritesPopoverIcon.png"),
              alt: ""
            }
          })
    ]),
    _c(
      "div",
      {
        staticClass: "addFavoritesButton",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.addNewFavorites.apply(null, arguments)
          }
        }
      },
      [_c("span", { staticClass: "iconfont_Me icon-plus iconStyle" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }