<template>
  <div class="favoritesHeader">
    <div class="favoritesIcon">
      <img v-if="showTransferIcon" :src="require('../../../assets/images/favorites/transferLargeIcon.png')">
      <img v-else :src="require('../../../assets/images/newPersonalCenter/favoritesPopoverIcon.png')" alt="">
    </div>
    <div class="addFavoritesButton" @click.stop="addNewFavorites">
      <span class="iconfont_Me icon-plus iconStyle"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "favoritesHeader",
  props: {
    // 展示转移图标
    showTransferIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 添加新的收藏夹
    addNewFavorites() {
      this.$emit("addNewFavorites");
    } 
  }
}
</script>
<style lang="stylus" scoped>
  .favoritesHeader
    width 100%;
    height 49px;
    display flex;
    padding 0 20px 0 9px;
    align-items center;
    box-sizing border-box;
    justify-content space-between;
    .favoritesIcon
      width 18px;
      height 17px;
      overflow hidden;
      img
        width 100%;
        height 100%;
    .addFavoritesButton
      width 60px;
      height 30px;
      cursor pointer;
      border-radius 15px;
      box-sizing border-box;
      border 1px solid #33CC66;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 16px;
        color #33CC66;

</style>