var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "favoritesListBox" },
    _vm._l(_vm.favoritesList, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "favoritesItem",
          class: item.isSelected ? "hasSelectedStyle" : "",
          attrs: { title: item.name },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.selectFavorites(index)
            }
          }
        },
        [
          _c("div", { staticClass: "favoritesInfo" }, [
            _c("div", { staticClass: "favoritesName" }, [
              _vm._v(_vm._s(item.name))
            ]),
            _c("div", { staticClass: "favoritesCount" }, [
              _vm._v(_vm._s(`(${item.count})`))
            ])
          ]),
          item.isSelected
            ? _c("div", { staticClass: "selectedIcon" }, [
                _c("span", { staticClass: "iconfont_Me icon-check iconStyle" })
              ])
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }