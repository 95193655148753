<template>
  <el-dialog
    :visible.sync="$store.state.favorites.favoritesDialogStatus"
    custom-class="favoritesDialog"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div
      class="dialogLoadingBlock"
      v-if="favoritesDialogLoading"
      v-loading="favoritesDialogLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    ></div>
    <SelectFavoritesBox
      v-if="
        needToShowInDialog === 'selectFavoritesBox' && !favoritesDialogLoading
      "
      :favoritesList="favoritesList"
      @closeDialog="closeDialog"
      @selectFavorites="selectFavorites"
    ></SelectFavoritesBox>
    <TransferFavoritesBlock
      v-if="
        needToShowInDialog === 'transferFavoritesBlock' &&
          !favoritesDialogLoading
      "
      :favoritesList="favoritesList"
      @closeDialog="closeDialog"
      @selectFavorites="selectFavorites"
    ></TransferFavoritesBlock>
    <EditFolderBlock
      v-if="needToShowInDialog === 'editFolderBlock' && !favoritesDialogLoading"
      @closeDialog="closeDialog"
    ></EditFolderBlock>
    <EditImFolderBlock
      v-if="
        needToShowInDialog === 'editImFolderBlock' && !favoritesDialogLoading
      "
      @closeDialog="closeDialog"
    ></EditImFolderBlock>
    <AddFolderBlock
      v-if="needToShowInDialog === 'addFolderBlock' && !favoritesDialogLoading"
      @closeDialog="closeDialog"
      @goBackPreviousBlock="goBackPreviousBlock"
    ></AddFolderBlock>
  </el-dialog>
</template>
<script>
import AddFolderBlock from "./dialogInner/addFolderBlock";
import EditFolderBlock from "./dialogInner/editFolderBlock";
import EditImFolderBlock from "./dialogInner/editImFolderBlock";
import SelectFavoritesBox from "./dialogInner/selectFavoritesBox";
import TransferFavoritesBlock from "./dialogInner/transferFavoritesBlock";
import {
  getFavoritesListData,
  getFavoritesCount,
} from "@/api/newVersion/interfaceForFavorites";
export default {
  name: "favoritesDialog",
  components: {
    AddFolderBlock,
    EditFolderBlock,
    EditImFolderBlock,
    SelectFavoritesBox,
    TransferFavoritesBlock,
  },
  computed: {
    // 当前需要展示的组件
    needToShowInDialog() {
      return this.$store.state.favorites.needToShowInDialog;
    },
    // 弹窗内容加载的时候显示loading
    favoritesDialogLoading() {
      return this.$store.state.favorites.favoritesDialogLoading;
    },
  },
  data() {
    return {
      // 收藏夹列表
      favoritesList: [],
      // 收藏夹总数
      favoritesCount: "0",
      // 记录上一个展示组件
      oldBlock: "",
    };
  },
  watch: {
    // 用于监听记录展示的组件模块,主要用于添加文件夹返回按钮
    "$store.state.favorites.needToShowInDialog": {
      handler(val, old) {
        if (val !== old) {
          this.oldBlock = old;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.$store.commit("setFavoritesDialogLoading", true);
    await this.getFavoritesCountData();
    await this.getFavoritesListData();
    this.$store.commit("setFavoritesDialogLoading", false);
  },
  methods: {
    // 获取收藏夹总数
    getFavoritesCountData() {
      return new Promise(async (resolve, reject) => {
        let result = await getFavoritesCount();
        if (result.code == 200) {
          this.favoritesCount = result.data.data;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        resolve("success");
      });
    },
    // 获取收藏夹列表
    getFavoritesListData() {
      return new Promise(async (resolve, reject) => {
        // 获取收藏夹列表
        let result = await getFavoritesListData();
        if (result.code == 200) {
          let favoritesList = result.data.data;
          this.favoritesList = favoritesList.map((item) => {
            item.isSelected = false;
            return item;
          });
          if (this.favoritesList.length > 0) {
            this.favoritesList[0].isSelected = true;
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        resolve("success");
      });
    },
    // 窗口关闭前执行
    closeDialog() {
      this.$store.dispatch("commitCloseFavoritesDialog");
    },
    // 从添加文件夹组件返回上一个
    async goBackPreviousBlock() {
      this.$store.commit("setFavoritesDialogLoading", true);
      await this.getFavoritesCountData();
      await this.getFavoritesListData();
      this.$store.commit("setFavoritesDialogLoading", false);
      this.$store.commit(
        "setNeedToShowInDialog",
        this.oldBlock !== "" ? this.oldBlock : "selectFavoritesBox"
      );
    },
    // 选择当前收藏夹
    selectFavorites(index) {
      this.favoritesList = this.favoritesList.map((item) => {
        item.isSelected = false;
        return item;
      });
      this.favoritesList[index].isSelected = true;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="stylus" scoped>
.favoritesDialog {
  .dialogLoadingBlock {
    width: 100%;
    height: 400px;
    background: #FFFFFF;
  }
}
</style>
<style lang="stylus">
.favoritesDialog {
  width: 400px !important;
  border-radius: 6px !important;
  margin-top: 0 !important;

  .dialogLoadingBlock {
    font-size: 24px !important;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
