var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.favorites.favoritesDialogStatus,
        "custom-class": "favoritesDialog",
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.favorites,
            "favoritesDialogStatus",
            $event
          )
        }
      }
    },
    [
      _vm.favoritesDialogLoading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.favoritesDialogLoading,
                expression: "favoritesDialogLoading"
              }
            ],
            staticClass: "dialogLoadingBlock",
            attrs: {
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "#FFFFFF"
            }
          })
        : _vm._e(),
      _vm.needToShowInDialog === "selectFavoritesBox" &&
      !_vm.favoritesDialogLoading
        ? _c("SelectFavoritesBox", {
            attrs: { favoritesList: _vm.favoritesList },
            on: {
              closeDialog: _vm.closeDialog,
              selectFavorites: _vm.selectFavorites
            }
          })
        : _vm._e(),
      _vm.needToShowInDialog === "transferFavoritesBlock" &&
      !_vm.favoritesDialogLoading
        ? _c("TransferFavoritesBlock", {
            attrs: { favoritesList: _vm.favoritesList },
            on: {
              closeDialog: _vm.closeDialog,
              selectFavorites: _vm.selectFavorites
            }
          })
        : _vm._e(),
      _vm.needToShowInDialog === "editFolderBlock" &&
      !_vm.favoritesDialogLoading
        ? _c("EditFolderBlock", { on: { closeDialog: _vm.closeDialog } })
        : _vm._e(),
      _vm.needToShowInDialog === "editImFolderBlock" &&
      !_vm.favoritesDialogLoading
        ? _c("EditImFolderBlock", { on: { closeDialog: _vm.closeDialog } })
        : _vm._e(),
      _vm.needToShowInDialog === "addFolderBlock" && !_vm.favoritesDialogLoading
        ? _c("AddFolderBlock", {
            on: {
              closeDialog: _vm.closeDialog,
              goBackPreviousBlock: _vm.goBackPreviousBlock
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }